@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1440,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
    :root {
        --step--2: clamp(0.6944rem, 0.6642rem + 0.1509vw, 0.8rem);
        --step--1: clamp(0.8331rem, 0.7854rem + 0.2384vw, 1rem);
        --step-0: clamp(1rem, 0.9286rem + 0.3571vw, 1.25rem);
        --step-1: clamp(1.2rem, 1.0964rem + 0.5179vw, 1.5625rem);
        --step-2: clamp(1.44rem, 1.2934rem + 0.733vw, 1.9531rem);
        --step-3: clamp(1.7281rem, 1.5244rem + 1.0188vw, 2.4413rem);
        --step-4: clamp(2.0738rem, 1.7943rem + 1.3973vw, 3.0519rem);
        --step-5: clamp(2.4881rem, 2.109rem + 1.8955vw, 3.815rem);
    }

    body,
    html {
        width: 100%;
        height: 100%;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    h1,
    h2,
    h3,
    h4 {
        font-family: 'Hind', sans-serif;
        font-weight: 700;
        font-style: normal;
        line-height: 1.1;
    }
}
