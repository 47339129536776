$size-sm : 640px !default;
$size-md : 769px !default;
$size-lg : 1024px !default;
$size-xl : 1280px !default;

@mixin breakpoint($size) {
    @if $size==sm {
        @media screen and (min-width:$size-sm) {
            @content;
        }
    }

    @else {
        @if $size==md {
            @media screen and (min-width:$size-md) {
                @content;
            }
        }

        @else {
            @if $size==lg {
                @media screen and (min-width:$size-lg) {
                    @content;
                }
            }

            @else {
                @if $size==xl {
                    @media screen and (min-width:$size-xl) {
                        @content;
                    }
                }
            }
        }
    }
}

// Stuff that matters.
@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(100px);
	}
}

svg #wheel {
	animation: scroll ease 1.5s infinite;
}

.text-animate {
    transition: color 0.8s ease-in-out;
    // transition-delay: 0.8s;
}

.backgroud-cross-shoes {
    background: url(../img/husox-mega-banner.png) no-repeat center center;
    background-size: cover;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(148, 147, 155, 0.5) 100%);
    }
    & > div {
        position: relative;
        z-index: 1;
    }
}

.background-form {
    background: url(../img/shoes-form-min.png) no-repeat center center;
    background-size: contain;
}

.tot-flow>*+* {
    margin-block-start: var(--flow-space, 1em);
}

.tot-prose {
    :is(h1, h2, h3, h4, h5, blockquote) {
        --flow-space: 1.25em;
    }

    :is(h1, h2, h3, h4, h5)+* {
        --flow-space: 1em;
    }

    p {
        font-size: inherit; //var(--step-5);
        line-height: 1.4;
        font-weight: 400;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        // font-family: 'Inter Display';
        font-weight: 700;
        line-height: 1.1;
        // text-wrap: balance;
    }

    h1 {
        font-size: var(--step-5);
    }

    h2 {
        font-size: var(--step-4);
    }

    h3 {
        font-size: var(--step-3);
    }

    h4 {
        font-size: var(--step-2);
    }

    h5 {
        font-size: var(--step-1);
    }

    h1 {
        max-width: 20ch;
    }

    :is(h1, h2, h3, h4, h5)+figure {
        padding-top: 0;
    }

    a {
        @apply underline;
    }

    ul {
        list-style-type: disc;
        padding-left: 1em;
        li {
            font-family: inherit;
            margin-bottom: .5em;
            font-weight: normal;
        }
    }
    ol {
        list-style-type:decimal;
        padding-left: 1em;
        li {
            font-family: inherit;
            margin-bottom: .5em;
            font-weight: normal;
        }
    }
}



